import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { fromSelectObject } from 'Utils/object';
import { withVariables } from 'Utils/string';

import { END_OPTIONS } from 'Consts/eventCycle';
import { ADEPT_EVENT_CYCLE } from 'Consts/routes';
 
import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layout/panel/ElementEditor';
import { TAB_EVENTS } from 'Components/pages/adept/EventCycle/component';

export default class PanelEventCycleEndEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        data: PropTypes.object,
        actions: PropTypes.shape({
            remove: PropTypes.func.isRequired,
        }).isRequired,
        onSuccess: PropTypes.func,
    };
    static defaultProps = {
        data: null,
    };

    state = {
        formState: {
            ...this.props.data, //eslint-disable-line react/destructuring-assignment
        },
    };

    onSubmit = formState => {
        const { actions, data, history } = this.props;

        return actions.remove({
            id: data.data.eventCycle.id,
            endOption: fromSelectObject(formState.endOption),
            eventId: data.data.id,
        }).then(() => {
            history.push(
                withVariables(ADEPT_EVENT_CYCLE.path, { id: data.data.eventCycle.originEventId }, { tab: TAB_EVENTS })
            );
        });
    }

    render() {
        const { location, history } = this.props;
        const { formState } = this.state;

        return (
            <StyledComponent
                className="adept-events-cycle-end-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    forms={[{
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'select',
                            name: 'endOption',
                            label: 'Co zrobić z wydarzeniami tego cyklu?',
                            options: END_OPTIONS.map(option => ({
                                value: option.key,
                                label: option.label,
                            })),
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}