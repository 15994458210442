import { css } from '@emotion/core';
import variables from 'Theme/styles/vars'; 

export default (props, state) => css`
    .selected-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        min-height: 45em;
    }

    .lead-continer {
        .any-trainer-element {
            width: 100%;
            height: 9em;
            padding: 1.5em 2em;
            border: 1px solid rgb(47 46 46);
            display: flex;
            line-height: 9em;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1em;

            .any-trainer-name {
                margin-left: 1em;
                text-transform: uppercase;
                font-size: .8em;
                font-weight: 400;
            }
            
            .any-trainer-button {
                margin-right: .5em;
                
                .component-button {

                    button {
                        width: 8.3em;
                        height: 2.6em;
                        font-size: 0.85em;
                        font-weight: 400;

                        .button-content {
                            font-size: 0.8em;
                        }
                    }
                }
            }
        }

        .admin-paginated-list-element-v2 {
            width: 100%;

            .wrapper {
                .block-left {
                    .title-block {          
                        .title-content {
                            width: 100%;
                            padding: 2em 0;

                            .title {
                                font-size: .8em;
                                font-weight: 400;
                            }

                            .subtitle {
                                font-size: .8em;
                            }
                        }
                    }
                }
            }
        }
    }

    .date-continer {
        margin-top: 2em;
    }

    @media (max-width: ${variables.tabletL}) {
        .admin-paginated-list-element-v2 {
            width: 100%;
        }
    }

    @media (max-width: ${variables.tabletS}) {
        .admin-paginated-list-element-v2 {
            padding: 2.5em 2em;
        }
    }

    @media (max-width: ${variables.mobileM}) {
        .lead-continer{
            .any-trainer-element{
                .any-trainer-name{
                    margin-left: 0;
                    font-size: 0.6em;
                    white-space: nowrap;
                }
            }
        }
    }
`;
