import { connect } from 'react-redux';
import Component from './component';

import {
    lastLocation, 
} from 'Redux/modules/adept/events/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            lastLocation: dispatch(lastLocation),
        },
    }),
)(Component);