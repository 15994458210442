import { FREQUENCY_TYPES } from 'Consts/eventCycle';

export const getFrequencyTypeLabel = frequencyType => {
    if(!frequencyType) {
        return { found: false, label: 'Nieznana' };
    }

    const type = FREQUENCY_TYPES.find(type => type.key === frequencyType)

    if (!type) {
        return { found: false, label: 'Nieznana' };
    }

    return { ...type, found: true };
};
