import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { withVariables } from 'Utils/string';

import { PUBLIC_SHOP, ADEPT_USER_OFFER } from 'Consts/routes';

import StyledComponent from 'Components/core/StyledComponent';
import ModalWrapper from 'Components/layout/modals/ModalWrapper';
import ModalBody from 'Components/layout/modals/ModalBody';
import ModalTitle from 'Components/layout/modals/ModalTitle';
import Button from 'Components/layout/Button';

export default class AdeptUserOfferPeriodEventsExceededModal extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isOpen: PropTypes.bool,
        onClose: PropTypes.func,
        title: PropTypes.string,
        headline: PropTypes.string,
        periodTypeSingle: PropTypes.string,
        userOfferId: PropTypes.string,
    };
    static defaultProps = {
        isOpen: false,
        onClose: null,
        title: null,
        headline: null,
        periodTypeSingle: null,
        userOfferId: null,
    };
    state = {};

    render() {
        const { location, history, periodTypeSingle, isOpen, onClose, title, headline, userOfferId } = this.props;

        return (
            <ModalWrapper
                location={location}
                history={history}
                isOpen={isOpen}
                onClose={onClose}
            >
                <StyledComponent 
                    className="adept-user-offer-period-events-exceeded-modal"
                    styles={require('./styles')}
                >
                    <ModalBody>
                        <ModalTitle title={title} />
                        <div className="headline">
                            <span>{headline}</span>
                        </div>
                        {periodTypeSingle 
                            ? <NavLink to={withVariables(PUBLIC_SHOP.path)}>
                                <Button
                                    className="button"
                                    type="button"
                                    style="gradient"
                                    icon={{ type: 'fa', source: 'fa fa-plus' }}
                                    layout="fullWidth"
                                    size="large"
                                >
                                    Dokup Więcej
                                </Button>
                            </NavLink>
                            :  <NavLink to={withVariables(ADEPT_USER_OFFER.path, { id: userOfferId })}>
                                <Button
                                    className="button"
                                    type="button"
                                    style="gradient"
                                    layout="fullWidth"
                                    size="large"
                                >
                                    Przedłuż zamówienie
                                </Button>
                            </NavLink>
                        }
                    </ModalBody>
                </StyledComponent>
            </ModalWrapper>
        );
    }
}
