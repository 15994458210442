import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    .adept-locations-list {
        .admin-paginated-list-element-v2 {
            width: 100%;
        }
    }
    .admin-paginated-list-element-v2 {
        width: 100%;
        .wrapper {
            .block-left {
                .title-block {          
                    .title-content {
                        max-width: 75%;

                        .title {
                            font-size: .8em;
                            font-weight: 400;
                        }

                        .subtitle {
                            font-size: .8em;
                        }
                    }
                }
            }
        }
    }
`;
