import { connect } from 'react-redux';
import Component from './component';

import { slotOccupation, suggestFreeSlots, lastLead } from 'Redux/modules/adept/events/actions';

export default connect(
    state => ({
        users: state.adeptUsers.list,
    }),
    dispatch => ({
        actions: {
            slotOccupation: dispatch(slotOccupation),
            suggestFreeSlots: dispatch(suggestFreeSlots),
            lastLead: dispatch(lastLead),
        },
    }),
)(Component);