import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`

    .dont-force-planner {
        .details {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 1em;

            .expand-details {
                width: 100%;
                text-align: center;
                margin: 1em auto;

                .expand-details-link {
                    color: ${variables.dpc_fontDark};
                    text-decoration: underline;
                    font-size: 1.1em;
                    cursor: pointer;
                }
            }

            .detail-item {
                display: flex;
                margin-bottom: .75em;
                color: white;
                width: 49.5%;

                .admin-panel-text-box {
                    width: 100%;
                    
                    .admin-panel-box {
                        .box-wrapper {
                            .right-block {
                                .subtitle {
                                    a {
                                        .link {
                                            color: black;
                                            text-decoration: underline;
                    
                                            &:hover {
                                                color: rgba(0,0,0,.8);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    
                }

                .label {
                    margin-right: .2em;
                }
                .value {
                    font-weight: 300;
                }

                &.training-plan {
                    width: 100%;
                    white-space: pre-line;

                    .admin-panel-text-box {
                        .admin-panel-box {
                            .box-wrapper {
                                .right-block {
                                    .subtitle {
                                        font-weight: 400;
                                        color: #C5C5C5;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .online-training {
                .admin-panel-text-box {
                    .admin-panel-box {
                        .box-wrapper {
                            .block {
                                .subtitle {
                                    .link {
                                        color: black;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .user-offer-users-type {
                .admin-panel-text-box {
                    .admin-panel-box {
                        .box-wrapper {
                            .block {
                                .image {
                                    svg {
                                        path { 
                                            fill: none !important; 
                                            stroke-width: 1.25em; 
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .route-select {
            .route-select-headline {
                font-size: 2em;
                color: ${variables.dpc_fontDark};
                font-weight: 300;
            }
            .routes {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 2em;

                .route {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    border: 1px solid #666666;
                    padding: 2em;
                    min-height: 15em;
                    cursor: pointer;
                    text-align: center;
                    margin: 0 1em;
                    width: 100%;

                    .route-headline {
                        color: ${variables.dpc_fontDark};
                        font-weight: 600;
                        font-size: 1.4em;
                    }

                    .route-hint {
                        color: ${variables.dpc_fontDark};
                        font-size: 1em;
                        font-weight: 300;
                        margin-top: 1em;

                        &.route-hint-alert {
                            color: #DD3544;
                            font-weight: 600;
                        }
                    }

                    &:hover {
                        background: ${variables.dpc_backgroundLightRed};
                        border-color: #DD3544;
                    }

                    &.disabled {
                        cursor: default;
                        background: #403f3f;

                        &:hover {
                            background: #403f3f;
                            border-color: #666666;
                        }
                    }
                }
            }
        }
        .footer {
            display: flex;
            justify-content: flex-end;
            margin-top: 1em;
        }

        .panel-step-accordion {
            .steps {
                .step {
                    .list-headline {
                        margin-bottom: 2em;
                        color: white;
                        font-size: 1.2em;
                        padding-top: 1em;
                    }
                }
            }
        }
    }

    @media (max-width: ${variables.tabletS}) {
        .dont-force-planner {
            .details {
                .detail-item {
                    width: 100%;
                    flex-wrap: wrap;
                }
            }
        }
    }

    @media (max-width: ${variables.mobileL}) {
        .dont-force-planner {
            .details {
                margin-bottom: 1em;
                
                .detail-item {
                    margin-bottom: 1.25em;
                    flex-wrap: wrap;

                    .label {
                        margin-right: .2em;
                        width: 100%;
                    }

                    .value {
                        margin-top: .5em;
                    }
                }
            }
        }

        .route-select {
            .routes {
                flex-wrap: wrap;
            }
        }
    }
`;
