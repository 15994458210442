import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TRAINER_DASHBOARD, TRAINER_EVENTS, TRAINER_EVENTS_MANAGE, TRAINER_ADEPTS_MANAGE } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Planner from 'Components/trainer/events/Planner';
import ModalEventCycleEnd from 'Components/trainer/modals/EventCycleEnd';

export const TAB_DATA = 'data';

export default class TrainerEventsManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        event: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};
    state = {
        isModalEventCycleEndVisible: false,
    };

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
    }

    render() {
        const { event, location, history } = this.props;
        const { isModalEventCycleEndVisible } = this.state;

        return (
            <StyledComponent
                className="trainer-events-manage"
                styles={require('./styles')}
            >
                <PageContent
                    elementMode={true}
                    data={event}
                    breadcrumbs={[{
                        to: withVariables(TRAINER_DASHBOARD.path),
                        label: 'Dashboard',
                    }, {
                        to: withVariables(TRAINER_EVENTS.path),
                        label: 'Wydarzenia',
                    }, {
                        to: withVariables(TRAINER_EVENTS_MANAGE.path, { id: event && event.data && event.data.id }),
                        label: `Wydarzenie: ${(event && event.data && event.data.product && event.data.product.name) || 'Prywatne' }`,
                    }]}
                >
                    {event && event.data && (
                        <TabNavigation
                            location={location}
                            history={history}
                            headline="Edytuj wydarzenie"
                            subHeadline={event.data.name}
                            controls={[{
                                visible: true,
                                key: 'return',
                                label: 'Wróć',
                                onClick: () => history.push(
                                    withVariables(TRAINER_ADEPTS_MANAGE.path, { id: event?.data?.user?.id }, { tab: 'eventsList' })
                                ),
                                style: 'gradient',
                                layout: 'wide',
                            }, {
                                visible: Boolean(event.data.eventCycle),
                                key: 'endCycle',
                                label: 'Zakończ cykl',
                                onClick: () => {
                                    this.setState({
                                        isModalEventCycleEndVisible: true,
                                    });
                                },
                                style: 'gradient',
                                layout: 'wide',
                            }]}
                            tabs={[{
                                key: TAB_DATA,
                                label: 'Dane',
                                children: (
                                    <Planner
                                        location={location}
                                        history={history}
                                        data={event.data}
                                        disabledSteps={{
                                            category: true,
                                            user: true,
                                            userOffer: true,
                                            product: true,
                                            location: true,
                                            time: false,
                                        }}
                                    />
                                ),
                            }]}
                        />
                    )}
                </PageContent>
                <ModalEventCycleEnd
                    location={location}
                    history={history}
                    isOpen={isModalEventCycleEndVisible}
                    onClose={() => this.setState({ isModalEventCycleEndVisible: false })}
                    onSuccess={() => this.setState({ isModalEventCycleEndVisible: false })}
                    data={event}
                />
            </StyledComponent>
        );
    }
}