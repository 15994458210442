import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { 
    USER_OFFER_STATUS_ACTIVE,
    USER_OFFER_STATUS_CLOSED,
} from 'Consts/userOffers';
import { OFFER_PERIOD_TYPE_SINGLE } from 'Consts/offers';
import { API_RESOURCE_EVENTS_STATS } from 'Consts/apiResources';

import StyledComponent from 'Components/core/StyledComponent';
import UserOffersList from 'Components/adept/userOffers/List';
import UserOfferPeriodEventsExceededModal from 'Components/adept/modals/UserOfferPeriodEventsExceededModal';

export default class AdeptEventsPlannerStepUserOffer extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        formState: PropTypes.object.isRequired,
        onChangeFormState: PropTypes.func.isRequired,
        actions: PropTypes.shape({
            stats: PropTypes.func.isRequired,
        }).isRequired,
    };
    static defaultProps = {};

    state = {
        isLoading: false,
        isBuyModalVisible: false,
    }

    onSelect = (userOffer) => {
        const { actions, onChangeFormState } = this.props;

        this.setState({ isLoading: true });
        
        return actions.stats({ userOfferId: userOffer.id })
            .then(response => {
                const elements = response.payload[API_RESOURCE_EVENTS_STATS];

                if(userOffer.periodType === OFFER_PERIOD_TYPE_SINGLE && elements.notPlanned.value <= 0) {
                    return this.setState({ 
                        isLoading: false,
                        isBuyModalVisible: true, 
                    });
                }

                this.setState({ isLoading: false });
                return onChangeFormState({ userOffer });
            })
            .catch(error => {
                this.setState({ isLoading: false });
                onChangeFormState({ userOffer });
            });
    }

    render() {
        const { location, history, formState } = this.props;
        const { isLoading, isBuyModalVisible } = this.state;

        return (
            <StyledComponent
                className="adept-events-planner-step-user-offer"
                styles={require('./styles')}
            >
                <h3 className="list-headline">
                    Wybierz zamówienie
                </h3>
                <UserOffersList 
                    location={location}
                    history={history}
                    onMapClassName={element => formState.userOffer && formState.userOffer.id === element.id
                        ? 'selected'
                        : ''
                    }
                    predefinedQuery={{
                        perPage: 6,
                        statuses: [USER_OFFER_STATUS_CLOSED, USER_OFFER_STATUS_ACTIVE],
                    }}
                    onMapControls={element => [{
                        type: 'button',
                        label:  formState.userOffer && formState.userOffer.id === element.id
                            ? 'Wybrano'
                            : 'Wybierz',
                        visible: true,
                        onClick: () => this.onSelect(element),
                        style: 'hollow',
                        loading: isLoading,
                    }]}
                />
                <UserOfferPeriodEventsExceededModal
                    location={location}
                    history={history}
                    isOpen={Boolean(isBuyModalVisible)}
                    title="Zamówienie zostało zrealizowane"
                    headling="Nie masz więcej slotów w tym zamówieniu, kup nowe zamówienie"
                    onClose={() => this.setState({ isBuyModalVisible: false })}
                    periodTypeSingle={OFFER_PERIOD_TYPE_SINGLE}
                />
            </StyledComponent>
        );
    }
}