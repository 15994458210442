import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import moment from 'moment';

import { FREQUENCY_TYPES } from 'Consts/eventCycle';
import { TIME_API_FORMAT } from 'Consts/date';

import { fromSelectObject } from 'Utils/object';
import { getFrequencyTypeLabel } from 'Utils/eventCycle';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import ElementEditor from 'Components/layout/panel/ElementEditor';
import ModalEventCycleSlots from 'Components/adept/modals/EventCycleSlots';

export default class AdeptEventCycle extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        cyclicEvent: PropTypes.object.isRequired,
    };
    static defaultProps = {
        cyclicEvent: null,
    };

    state = {
        isModalEventCycleSlotsVisible: false,
        formState: this.props.cyclicEvent || {},  //eslint-disable-line react/destructuring-assignment
    };

    componentDidUpdate = (prevProps, prevState) => {
        const { cyclicEvent } = this.props;

        if (cyclicEvent && JSON.stringify(cyclicEvent) !== JSON.stringify(prevProps.cyclicEvent)) {
            this.setState(prevState => ({
                ...prevState,
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(cyclicEvent),
                },
            }));
        }
    }

    onSubmit = (formState) => {
        if (!formState.frequencyType || !formState.startAt.isValid() || !formState.endAt.isValid() || !formState.eventStartTime.isValid()) {
            toast.error('Wypełnij wszystkie pola');
        } else {
            this.formStateToData(formState);
            this.setState({
                isModalEventCycleSlotsVisible: true,
            });
        }
    }

    dataToFormState = data => ({
        ...data,
        startAt: data?.startAt?.isValid() ? data.startAt : moment(data?.originEvent?.startAt).add(1, 'week'),
        endAt: data?.endAt?.isValid() ? data?.endAt : moment(data?.originEvent?.startAt).add(3, 'months'),
        eventStartTime: data?.eventStartTime ? moment(data?.eventStartTime, TIME_API_FORMAT) : moment(data?.originEvent?.startAt),
        frequencyType: data?.frequencyType
            ? { value: data.frequencyType, label: getFrequencyTypeLabel(data.frequencyType).label}
            : null,
    })

    formStateToData = formState => ({
        frequencyType: fromSelectObject(formState.frequencyType)
    })

    render() {
        const { cyclicEvent, location, history } = this.props;
        const { formState, isModalEventCycleSlotsVisible } = this.state;

        if (!cyclicEvent) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="adept-event-cycle"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    layout="box"
                    styleVersion={2} 
                    forms={[{
                        title: 'Zarządzaj cyklem',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'select',
                            name: 'frequencyType',
                            label: 'Cykliczność',
                            options: FREQUENCY_TYPES.map(type => ({
                                value: type.key,
                                label: type.label,
                            })),
                            required: true,
                        }, {
                            type: 'datePicker',
                            name: 'startAt',
                            label: 'Data startu cyklu',
                            inputProps: {
                                datePickerProps: {
                                    dateFormat: 'yyyy-MM-dd',
                                    timeIntervals: 15,
                                },
                                border: 'light-2', 
                            },
                            required: true,
                        }, {
                            type: 'datePicker',
                            name: 'endAt',
                            label: 'Data końca cyklu',
                            inputProps: {
                                datePickerProps: {
                                    dateFormat: 'yyyy-MM-dd',
                                    timeIntervals: 15,
                                    minDate: moment().toDate(),
                                    maxDate: cyclicEvent?.extendCycle
                                        ? moment().add(12, 'months').toDate()
                                        : moment().add(6, 'months').toDate(),
                                },
                                border: 'light-2', 
                            },
                            required: true,
                        }, {
                            type: 'datePicker',
                            name: 'eventStartTime',
                            label: 'Godzina startu wydarzeń',
                            inputProps: {
                                datePickerProps: {
                                    dateFormat: 'HH:mm',
                                    timeIntervals: 15,
                                    showTimeSelectOnly: true,
                                },
                                border: 'light-2', 
                            },
                            required: true,
                        }]
                    }]}
                />
                <ModalEventCycleSlots
                    location={location}
                    history={history}
                    isOpen={isModalEventCycleSlotsVisible}
                    onClose={() => this.setState({ isModalEventCycleSlotsVisible: false })}
                    onSuccess={() => this.setState({ isModalEventCycleSlotsVisible: false })}
                    data={formState}
                />
            </StyledComponent>
        );
    }
}