import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { ADEPT_EVENTS } from 'Consts/routes';
import { PLAN_LIMIT_ADEPT_TYPE, PLAN_LIMIT_ADEPT_VALUE } from 'Consts/events';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import Page from 'Components/layout/panel/Page';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Planner from 'Components/adept/events/Planner';
import ModalEventCycleEnd from 'Components/adept/modals/EventCycleEnd';

export const TAB_DATA = 'data';

export default class AdeptEventsManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        event: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};
    state = {
        isModalEventCycleEndVisible: false,
    };

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
    }

    render() {
        const { event, location, history } = this.props;
        const { isModalEventCycleEndVisible } = this.state;

        return (
            <StyledComponent
                className="adept-events-manage"
                styles={require('./styles')}
            >
                <Page
                    elementMode={true}
                    data={event}
                >
                    {event && event.data && (
                        <TabNavigation
                            location={location}
                            history={history}
                            headline="Zarządzaj wydarzeniem"
                            controls={[{
                                visible: true,
                                key: 'return',
                                label: 'Wróć',
                                onClick: () => history.push(
                                    withVariables(ADEPT_EVENTS.path, {}, { tab: 'future' }),
                                ),
                                style: 'gradient',
                                layout: 'wide',
                            }, {
                                visible: Boolean(event.data.eventCycle),
                                key: 'endCycle',
                                label: 'Zakończ cykl',
                                onClick: () => {
                                    this.setState({
                                        isModalEventCycleEndVisible: true,
                                    });
                                },
                                style: 'gradient',
                                layout: 'wide',
                            }]}
                            tabs={[{
                                key: TAB_DATA,
                                label: 'Dane',
                                children: (
                                    <Planner
                                        location={location}
                                        history={history}
                                        data={event.data}
                                        disabledSteps={{
                                            userOffer: true,
                                            product: true,
                                            location: true,
                                        }}
                                        maxDate={moment().add(PLAN_LIMIT_ADEPT_VALUE, PLAN_LIMIT_ADEPT_TYPE)}
                                    />
                                ),
                            }]}
                        />
                    )}
                </Page>
                <ModalEventCycleEnd
                    location={location}
                    history={history}
                    isOpen={isModalEventCycleEndVisible}
                    onClose={() => this.setState({ isModalEventCycleEndVisible: false })}
                    onSuccess={() => this.setState({ isModalEventCycleEndVisible: false })}
                    data={event}
                />
            </StyledComponent>
        );
    }
}