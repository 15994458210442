import { reduxRequest as request } from 'Services/Api';
import * as types from './types';
import * as endpoints from 'Consts/api';

import { fromSelectObject } from 'Utils/object';

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: endpoints.ENDPOINT_ADEPT_USERS,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            search: params.search || undefined,
            orderBy: params.orderBy || 'createdAt',
            canLeadProductId: fromSelectObject(params.canLeadProductId) || undefined,
            locationId: fromSelectObject(params.locationId) || undefined,
            enabled: params.enabled || undefined,
        },
    }));
};

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE,
        method: 'GET',
        path: endpoints.ENDPOINT_ADEPT_USER
            .replace('{id}', params.id),
        params,
    }));
};
