import { connect } from 'react-redux';
import Component from './component';

import {
    remove,
} from 'Redux/modules/trainer/cyclicEvent/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            remove: dispatch(remove),
        },
    }),
)(Component);