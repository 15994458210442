import { connect } from 'react-redux';
import Component from './component';

import { list as listProducts } from 'Redux/modules/adept/products/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            listProducts: dispatch(listProducts),
        },
    }),
)(Component);