import { connect } from 'react-redux';
import Component from './component';

import {
    checkCycleSlotsAvailability,
    createMany,
} from 'Redux/modules/adept/events/actions'
import {
    update as updateCycle,
} from 'Redux/modules/adept/cyclicEvent/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            checkCycleSlotsAvailability: dispatch(checkCycleSlotsAvailability),
            createMany: dispatch(createMany),
            updateCycle: dispatch(updateCycle),
        },
    }),
)(Component);