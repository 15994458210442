import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import {
    FREQUENCY_TYPE_EVERY_2_WEEKS,
    FREQUENCY_TYPE_EVERY_WEEK,
} from 'Consts/eventCycle';
import { ADEPT_EVENTS_MANAGE } from 'Consts/routes';

import { toApiFormat as dateToApiFormat } from 'Utils/date';
import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layout/panel/ElementEditor';

export default class PanelEventCycleSlotsEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        data: PropTypes.object,
        actions: PropTypes.shape({
            checkCycleSlotsAvailability: PropTypes.func.isRequired,
            createMany: PropTypes.func.isRequired,
            updateCycle: PropTypes.func.isRequired,
        }).isRequired,
        onSuccess: PropTypes.func,
    };
    static defaultProps = {
        data: null,
    };

    state = {
        formState: {
            ...this.props.data, //eslint-disable-line react/destructuring-assignment
        },
        trainingsDates: null,
        errors: {},
        dates: [],
    };

    componentDidMount = () => {
        const { data } = this.props;

        this.dataToDates().then(() => {
            this.fillFormState(data).then(() => {
                this.checkCycleSlotsAvailability();
            });
        });
    }

    onSubmit = () => {
        const { actions, data, history } = this.props;
        const { errors } = this.state;

        if (Object.keys(errors).length === 0) {
            actions.updateCycle({
                id: data.id,
                originEventId: data.originEvent.id,
                leadId: data.originEvent.lead.id,
                userId: data.originEvent.user.id,
                frequencyType: data.frequencyType.value,
                eventStartTime: dateToApiFormat(data.eventStartTime, 'time', true),
                startAt: dateToApiFormat(data.startAt, 'datetime', true),
                endAt: dateToApiFormat(data.endAt, 'datetime', true),
            }).then(() => {
                toast('Cykl został zmieniony');
            });

            actions.createMany({
                eventsToCreate: this.getEventsToCreate(),
                cyclicEventId: data.id,
            }).then(() => {
                history.push(
                    withVariables(ADEPT_EVENTS_MANAGE.path, { id: data.originEvent.id }, {})
                );

                toast('Wydarzenia zostały utworzone');
            });
        } else { 
            toast.error('Formularz zawiera błędy');
        }
    }

    formStateToApi = data => ({
        ...data,
    })

    fillFormState = data => {     
        return new Promise(resolve => {
            const { formState, dates } = this.state;

            let hour = Number(data.eventStartTime.format('HH'));
            let minute = Number(data.eventStartTime.format('mm'));
            let elements = dates;
            let date = data.startAt.clone().set('hour', hour).set('minute', minute);
            let newFormState = formState;

            let startAts = elements.filter(element => element.name.includes('startAt'));

            startAts.forEach(element => {
                newFormState = {
                    ...newFormState,
                    [element.name]: date.clone(),
                };

                if (element.name.includes('startAt_')) {
                    switch (data.frequencyType.value) {
                        case FREQUENCY_TYPE_EVERY_2_WEEKS:
                            date = date.add(2, 'weeks');
                            break;
                        case FREQUENCY_TYPE_EVERY_WEEK:
                            date = date.add(1, 'week');
                            break;
                    }
                }
            });
            
            this.setState({
                formState: newFormState
            }, () => {
                resolve();
            });
        });
    }

    dataToDates = () => {
        return new Promise(resolve => {
            const { data } = this.props;
        
            let availableSlots = data.endAt.diff(data.startAt, 'week'); // how many weeks between cycle start and cycle end
            let dates = [];
    
            if (data?.frequencyType?.value === FREQUENCY_TYPE_EVERY_2_WEEKS) { // if every 2 weeks then take 1/2 of available weeks
                availableSlots = availableSlots / 2;
            }
    
            for (let i = 1; i <= availableSlots; i++) {
                dates.push({
                    isVisible: true,
                    type: 'datePicker',
                    name: 'startAt_' + i, //startAt_1, startAt_2, startAt_3, etc.
                    label: 'Data startu ' + i + '. wydarzenia', // 1, 2, 3, etc.
                    inputProps: {
                        datePickerProps: {
                            dateFormat: 'yyyy-MM-dd HH:mm',
                        },
                    },
                });
    
                let key = 'remove_date_' + i.toString();
                dates.push({
                    isVisible: true,
                    type: 'element',
                    name: key,
                    children: (
                        <a
                            className="event-cycle-remove-slot-button"
                            onClick={() =>  this.removeSlot(i)}
                        >
                            Usuń
                        </a>
                    )
                });
            }
    
            this.setState({
                dates
            }, () => {
                resolve();
            });
        });
    }

    checkCycleSlotsAvailability = () => {
        const { actions } = this.props;

        this.setState({
            errors: {}
        });

        this.setTrainingDates();

        actions.checkCycleSlotsAvailability({
            eventsToCreate: this.getEventsToCreate(),
        })
        .catch(error => {
            const errors = error.payload.originalError.data.occupiedDates;
            Object.keys(errors).map(index => {
                const newIndex = Number(index) + 1;
                let fieldName = 'startAt_' + newIndex.toString();

                this.setState(prevState => ({
                    errors: {
                        ...prevState.errors,
                        [fieldName]: [
                            errors[index]
                        ],
                    }
                }));
            });
        });
    }

    removeSlot = (buttonKeyNumber) => {
        const { dates, formState } = this.state;
        let elementsToRemove = dates.filter(date => date.name.includes(buttonKeyNumber.toString()));

        elementsToRemove.forEach(elementToRemove => {
            let index = dates.map(function (e) { 
                return e.name; 
            }).indexOf(elementToRemove.name);

            delete formState[elementToRemove.name];

            if (index > -1) {
                dates.splice(index, 1);
            }
        });

        this.setState({
            dates,
            formState,
        });
    }

    setTrainingDates = () => {
        const { formState } = this.state;

        let trainingsDates = [];

        Object.keys(formState).forEach(key => {
            if (key.includes('startAt_')) {
                let dateToAdd = formState[key];
                trainingsDates.push(dateToApiFormat(dateToAdd, 'datetime', true));
            }
        });

        this.setState({
            trainingsDates,
        });

        return trainingsDates;
    }

    getEventsToCreate = () => {
        const { data } = this.props;
        const { trainingsDates } = this.state;

        let eventsToCreate = [];

        trainingsDates.forEach(trainingDate => {
            eventsToCreate.push({
                leadId: data.originEvent.lead.id,
                startAt: trainingDate,
                userOfferId: data.originEvent.userOffer.id,
                productId: data.originEvent.product.id,
                locationId: data.originEvent.location.id,
            });
        });

        return eventsToCreate;
    }

    render() {
        const { location, history } = this.props;
        const { formState, errors, dates } = this.state;

        return (
            <StyledComponent
                className="adept-events-cycle-slots-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    forms={[{
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: dates,
                        errors: errors,
                    }]}
                />
            </StyledComponent>
        );
    }
}