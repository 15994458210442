import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { API_RESOURCE_USER } from 'Consts/apiResources';
import { DEFAULT_TIME_INTERVALS } from 'Consts/date';

import { getDateRange } from 'Utils/date';
import { getFullName, getUserSpecializations } from 'Utils/user';
import { getUserWorkHours } from 'Utils/datepicker';

import StyledComponent from 'Components/core/StyledComponent';
import UsersList from 'Components/adept/users/List';
import PaginatedListElement from 'Components/layout/panel/PaginatedListElementV2';
import Button from 'Components/layout/Button';
import SlotSelectDate from 'Components/adept/events/SlotSelectDate';
import UserOfferPeriodEventsExceededModal from 'Components/adept/modals/UserOfferPeriodEventsExceededModal';

export default class AdeptEventsPlannerStepLeadAndTime extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            slotOccupation: PropTypes.func.isRequired,
            lastLead: PropTypes.func.isRequired,
        }).isRequired,
        users: PropTypes.object.isRequired,
        formState: PropTypes.object.isRequired,
        onChangeFormState: PropTypes.func.isRequired,
        maxDate: PropTypes.object,
    };
    static defaultProps = {};

    state = {
        eventFreeSlots: [],
        trainersPerPage: 4,
        defaultTrainersPerPage: 4,
        maxDate: moment().add(2, 'months'),
        isBuyModalVisible: false,
    }

    componentDidMount = () => {
        const { actions, formState, onChangeFormState } = this.props;

        if (!formState.lead) {
            actions.lastLead({ 
                productId: formState.product && formState.product.id,
                locationId: formState.location && formState.location.id,
            })
                .then(response => {
                    const lead = response.payload[API_RESOURCE_USER];
                    if (lead) {
                        onChangeFormState({ lead });
                    }
                });
        }
    }

    getUserWorkHoursDateRange = (date) => {
        const { formState } = this.props; 
        const leadWorkHours = getUserWorkHours(formState?.lead?.userWorkHours, date) || null;
        
        if (!date) date = null;

        if (leadWorkHours) {
            const userWorkHoursStartAt = moment(date).startOf('day')
                .add(leadWorkHours.startAtHour, 'hours')
                .add(leadWorkHours.startAtMinute, 'minutes');
            const userWorkHoursEndAt = moment(date).startOf('day')
                .add(leadWorkHours.endAtHour, 'hours')
                .add(leadWorkHours.endAtMinute, 'minutes');
    
            const startAtWorkHoursDateRange = getDateRange(moment(date).startOf('day'), userWorkHoursStartAt, 'minutes', DEFAULT_TIME_INTERVALS, 'toDate');
            const endAtWorkHoursDateRange = getDateRange(userWorkHoursEndAt, moment(date).endOf('day'), 'minutes', DEFAULT_TIME_INTERVALS, 'toDate');

            return [startAtWorkHoursDateRange, endAtWorkHoursDateRange];
        }

        return null;
    }

    onSelectSlot = slot => {
        const { onChangeFormState, formState } = this.props;

        const startAt = slot?.time ? moment(slot.time) : null;
        const endAt = !startAt ? null : startAt.clone().add(formState?.product?.durationValue, formState?.product?.durationType);

        // if(slot) {
        //     const slotTimeIsBeforePaidTo = moment(slot.time).isBefore(moment(formState.userOffer?.paidTo));
        //     if (slotTimeIsBeforePaidTo) {
        //         this.setState({ isBuyModalVisible: true });
        //     }
        // }

        onChangeFormState({ 
            slot,
            startAt,
            endAt,
        });
    }

    getStartAt = startAt => {
        if (startAt.isSame(moment(), 'day')) {
            return moment();
        }
        
        return startAt;
    }

    render() {
        const { location, history, formState, onChangeFormState, maxDate } = this.props;
        const { trainersPerPage, defaultTrainersPerPage, isBuyModalVisible } = this.state;

        return (
            <StyledComponent
                className="adept-events-planner-step-lead-and-time"
                styles={require('./styles')}
            >
                {(formState.lead || formState.anyTrainer) && (
                    <div className="selected-container">
                        <div className="lead-continer">
                            <h2 className="list-headline">
                                {formState.anyTrainer ? "Wybrano dowolnego prowadzącego" : "Wybrany prowadzący"}
                            </h2>
                            <PaginatedListElement
                                title={formState.anyTrainer ? "Dowolny prowadzący" : getFullName(formState.lead, 'nameSurname').label}
                                subtitle={formState.anyTrainer ? null : getUserSpecializations(formState.lead, true)}
                                image={{
                                    visible: true,
                                    url: formState.anyTrainer 
                                        ? require('Theme/images/placeholders/avatar.png')
                                        : formState.lead.avatar || require('Theme/images/placeholders/avatar.png'),
                                }}
                                controls={[{
                                    type: 'button',
                                    label:  'Zmień',
                                    visible: true,
                                    onClick: () => {
                                        onChangeFormState({ lead: null, anyTrainer: false });
                                    },
                                    style: 'hollow',
                                }]}
                            />
                        </div>
                        <div className="date-continer">
                            <h3 className="list-headline">
                                Wybierz termin
                            </h3>
                            <SlotSelectDate 
                                location={location}
                                history={history}
                                selectedLocation={formState.location}
                                selectedProduct={formState.product}
                                selectedLead={formState.anyTrainer ? null : formState.lead}
                                selectedSlot={formState.slot}
                                onSelectSlot={this.onSelectSlot}
                                maxDate={maxDate}
                            />
                        </div>
                    </div>
                )}
                <UserOfferPeriodEventsExceededModal
                    location={location}
                    history={history}
                    isOpen={Boolean(isBuyModalVisible)}
                    title="Zamówienie zostało zrealizowane"
                    headline="W tym okresie rozliczeniowym nie masz już więcej slotów, jeśli chcesz zaplanować więcej wydarzeń, przedłuż zamówienie już teraz"
                    onClose={() => this.setState({ isBuyModalVisible: false })}
                    userOfferId={formState.userOffer?.id}
                />
                {!formState.lead && !formState.anyTrainer && (
                    <div className="lead-continer">
                        <h3 className="list-headline">
                            Wybierz prowadzącego
                        </h3>
                        <div className="any-trainer-element">
                            <div className="any-trainer-name">
                                Dowolny prowadzący
                            </div>
                            <div className="any-trainer-button">
                                <Button
                                    style="hollow"
                                    size="small"
                                    onClick={() => onChangeFormState({ anyTrainer: true })}
                                >
                                    Wybierz
                                </Button>
                            </div>
                        </div>
                        <UsersList 
                            location={location}
                            history={history}
                            predefinedQuery={{
                                perPage: trainersPerPage,
                                locationId: formState.location.id || null,
                                canLeadProductId: formState && formState.product && formState.product.id || null,
                            }}
                            onMapControls={element => [{
                                type: 'button',
                                label: 'Wybierz',
                                visible: true,
                                onClick: () => onChangeFormState({ lead: element }),
                                style: 'hollow',
                            }]}
                            resetQuery={true}
                            showSpecialization={true}
                        />
                        {trainersPerPage == defaultTrainersPerPage && (
                            <Button
                                onClick={() => this.setState({
                                    trainersPerPage: 10,
                                })}
                                style="hollow"
                            >
                                Pokaż więcej
                            </Button>
                        )}
                    </div>
                )}
            </StyledComponent>
        );
    }
}