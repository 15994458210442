import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    .headline {
        font-size: 1.2em;
        padding-bottom: 1em;
    }

    @media (min-width: ${variables.tabletL}) {}
`;
