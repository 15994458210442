import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    width: 80vw;

    @media (max-width: ${variables.tabletS}) {
        .modal-body-component {
            .adept-events-cycle-slots-editor {
                .admin-element-editor {
                    .editor-wrapper {
                        .forms-wrapper {
                            .form-wrapper {
                                .admin-form-generator {
                                    .form-elements {
                                        flex-direction: column;

                                        .date-picker {
                                            width: 100%;

                                            .input-external-wrapper {
                                                .input-internal-wrapper {
                                                    .react-datepicker-wrapper {
                                                        .react-datepicker__input-container {
                                                            input {
                                                                text-align: center;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        .event-cycle-remove-slot-button {
                                            text-align: center;
                                            margin-bottom: 3em;
                                            margin-top: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }       
    }
`;
