import moment from 'moment';

import { parseWorkHours } from 'Utils/userWorkHours';

export const getUserWorkHours = (userWorkHours, date) => {
    const parsedWorkHours = parseWorkHours(userWorkHours, true);
    let dayId;

    if (parsedWorkHours) {
        if (!date) {
            dayId = moment().day();
        } else {
            dayId = moment(date).day();
        }
       
        return parsedWorkHours.find(workHour => workHour.momentId == dayId); 
    }

    return null;
};