import { connect } from 'react-redux';
import Component from './component';

import { freeSlotsByScore } from 'Redux/modules/public/events/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            freeSlotsByScore: dispatch(freeSlotsByScore),
        },
    }),
)(Component);
