import { connect } from 'react-redux';
import Component from './component';

import {
    update,
    create,
    done,
    cancel,
} from 'Redux/modules/adept/events/actions';
import { list as listUserOffers } from 'Redux/modules/adept/userOffers/actions';
import { list as listProducts } from 'Redux/modules/adept/products/actions';

export default connect(
    state => ({
        stats: state.adeptEvents.stats,
    }),
    dispatch => ({
        actions: {
            update: dispatch(update),
            create: dispatch(create),
            done: dispatch(done),
            cancel: dispatch(cancel),
            listUserOffers: dispatch(listUserOffers),
            listProducts: dispatch(listProducts),
        },
    }),
)(Component);