import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import { 
    API_RESOURCE_EVENT, 
    API_RESOURCE_USER_OFFERS, 
    API_RESOURCE_PRODUCTS,
} from 'Consts/apiResources';
import { 
    ADEPT_EVENTS, 
    ADEPT_EVENT_CYCLE,
    ADEPT_EVENT_MEET,
} from 'Consts/routes';
import { EVENT_DONE_STATUS_NOT_DONE } from 'Consts/events';
import { USER_OFFER_STATUS_ACTIVE } from 'Consts/userOffers';
import { LOCATION_TYPE_ONLINE } from 'Consts/locations';
import { OFFER_TYPE_DIAGNOSTIC } from 'Consts/offers';

import { getFullName } from 'Utils/user';
import { getFormattedDate, toApiFormat as dateToApiFormat, translateTimeUnit } from 'Utils/date';
import { getCategoryLabel } from 'Utils/event';
import { parseQueryToObject } from 'Utils/querystring';
import { withVariables } from 'Utils/string';

import Logger from 'Services/Logger';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import StepAccordion from 'Components/layout/panel/StepAccordion';
import StepAccordionV2 from 'Components/layout/panel/StepAccordionV2';
import StepUserOffer from 'Components/adept/events/Planner/steps/UserOffer';
import StepProduct from 'Components/adept/events/Planner/steps/Product';
import StepLocation from 'Components/adept/events/Planner/steps/Location';
import StepLeadAndTime from 'Components/adept/events/Planner/steps/LeadAndTime';
import Button from 'Components/layout/Button';
import TextBox from 'Components/layout/panel/TextBox';

export default class AdeptEventsPlanner extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            create: PropTypes.func.isRequired,
            update: PropTypes.func.isRequired,
            done: PropTypes.func.isRequired,
            cancel: PropTypes.func.isRequired,
            listUserOffers: PropTypes.func.isRequired,
            listProducts: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
        predefinedState: PropTypes.object,
        onSuccess: PropTypes.func,
        forcePlanner: PropTypes.bool,
        disabledSteps: PropTypes.shape({
            userOffer: PropTypes.bool,
            product: PropTypes.bool,
            location: PropTypes.bool,
            leadAndTime: PropTypes.bool,
        }),
        maxDate: PropTypes.object,
    };
    static defaultProps = {
        data: null,
        predefinedState: {},
        forcePlanner: false,
        disabledSteps: {
            userOffer: false,
            product: false,
            location: false,
            leadAndTime: false,
        },
        maxDate: moment().add(2, 'months'),
    };

    state = {
        formState: {},
        products: [],
        isInitialized: false,
        forcePlanner: this.props.forcePlanner, //eslint-disable-line react/destructuring-assignment
        isDetailsExpanded: false,
        width: 0,
        height: 0,
        disabledSteps: {
            userOffer: this.props.disabledSteps.userOffer, //eslint-disable-line react/destructuring-assignment
            product: this.props.disabledSteps.product, //eslint-disable-line react/destructuring-assignment
            location: this.props.disabledSteps.location, //eslint-disable-line react/destructuring-assignment
            leadAndTime: this.props.disabledSteps.leadAndTime, //eslint-disable-line react/destructuring-assignment
        },
    };

    componentDidMount = () => {
        const { data, predefinedState, actions } = this.props;
        this.setData();

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data || {}),
                ...predefinedState,
            },
        }));
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { data, history, location } = this.props;
        const { formState, forcePlanner } = this.state;
        const queryObject = parseQueryToObject(location.search, true);

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                ...prevState,
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }

        // Clear page param on formState change
        if(queryObject.page && JSON.stringify(formState) !== JSON.stringify(prevState.formState)) {
            history.push(
                withVariables(
                    location.pathname,
                    {},
                    { ...queryObject, page: undefined }
                )
            );
        }

        if(forcePlanner) {
            // Clear next steps on userOffer change
            if(formState.userOffer && JSON.stringify(formState.userOffer) !== JSON.stringify(prevState.formState.userOffer)) {
                this.setState(prevState => ({
                    formState: {
                        ...prevState.formState,
                        product: null,
                        location: null,
                        leadAndTime: null,
                    },
                }));
            }

            // Clear next steps on product change
            if(formState.product && JSON.stringify(formState.product) !== JSON.stringify(prevState.formState.product)) {
                this.setState(prevState => ({
                    formState: {
                        ...prevState.formState,
                        location: null,
                        leadAndTime: null,
                    },
                }));
            }

            // Clear next steps on location change
            if(formState.location && JSON.stringify(formState.location) !== JSON.stringify(prevState.formState.location)) {
                this.setState(prevState => ({
                    formState: {
                        ...prevState.formState,
                        leadAndTime: null,
                    },
                }));
            }
        }
    }

    updateWindowDimensions = () => {
        this.setState({ 
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    setData = () => {
        const { actions } = this.props;
        const { formState } = this.state; 
        
        new Promise(resolve => {
            actions.listUserOffers({ 
                page: 1, 
                perPage: 2,
                status: USER_OFFER_STATUS_ACTIVE,
            })
                .then(response => {
                    const elements = response.payload[API_RESOURCE_USER_OFFERS].elements;

                    if (formState.userOffer) {
                        this.setState({
                            formState: {
                                ...formState,
                                product: null,
                            },
                        });
                    }

                    if (elements.length > 1) {
                        return resolve();
                    }

                    const currentElement = elements[0];
                    this.setState(prevState => ({
                        ...prevState,
                        formState: {
                            ...prevState.formState,
                            userOffer: currentElement,
                        },
                    }), resolve);
                })
                .catch(error => {
                    Logger.error('[ADEPT_PLANNER] Error', error);
    
                    Object.keys(error.payload.validationErrors).map(fieldName => {
                        error.payload.validationErrors[fieldName].map(errorMessage => {
                            toast(`${fieldName}: ${errorMessage}`);
                        });
                    });

                    if (Object.keys(error.payload.validationErrors).length == 0) {
                        toast('Wystąpił błąd, spróbuj ponownie później');
                    } 
                });
        })
            .then(() => {
                const { formState } = this.state;
                if (!formState.userOffer) {
                    return;
                }

                actions.listProducts({ 
                    page: 1, 
                    perPage: 2, 
                    userOfferId: formState.userOffer && formState.userOffer.id,
                })
                    .then(response => {
                        const elements = response.payload[API_RESOURCE_PRODUCTS].elements;
                        if (elements.length > 1) {
                            return;
                        }
        
                        const currentElement = elements[0];
                        if(elements.length == 1) {
                            this.setState(prevState => ({
                                ...prevState,
                                formState: {
                                    ...prevState.formState,
                                    product: currentElement,
                                },
                            }));
                        }
                    });
            })
            .then(() => {
                this.setState({
                    isInitialized: true,
                });
            });
    }

    dataToFormState = data => ({
        ...data,
        startAt: data.startAt && moment(data.startAt).toDate(),
    })

    formStateToApi = data => ({
        ...data,
        userOfferId: data.userOffer && data.userOffer.id,
        productId: data.product && data.product.id,
        leadId: data.slot.trainerId,
        locationId: data.location && data.location.id,
        startAt: dateToApiFormat(data.startAt, 'datetime', true),
        endAt: dateToApiFormat(data.endAt, 'datetime', true),
    })

    onSubmit = () => {
        const { data } = this.props;
        const { formState } = this.state;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onCreate = formState => {
        const { actions, history, onSuccess } = this.props;

        return actions.create({
            ...this.formStateToApi(formState),
        })
            .then(response => {
                const data = response.payload[API_RESOURCE_EVENT];
                if (onSuccess) {
                    return onSuccess(data);
                }

                history.push(ADEPT_EVENTS.path);
                toast('Dodano wydarzenie');
            })
            .catch((error) => {
                Object.keys(error.payload.validationErrors).map(fieldName => {                       
                    error.payload.validationErrors[fieldName].map(errorMessage => {
                        toast.error(errorMessage);
                    });
                });

                if (Object.keys(error.payload.validationErrors).length == 0) {
                    toast('Wystąpił błąd, spróbuj ponownie później');
                }
            });
    }

    onUpdate = formState => {
        const { data, actions, onSuccess } = this.props;

        if (formState.offer && formState.offer.type == OFFER_TYPE_DIAGNOSTIC && formState.moveCount > 0) {
            const confirmRedirection = window.confirm('Nie możesz przełozyć treningu diagnostycznego więcej niż 1 raz. Jeśli chcesz przełożyć trening ponownie, kliknij potwierdź aby udać się na czat obsługi klienta.');
            
            if (confirmRedirection) {
                return window.open('https://www.facebook.com/fitadept/');
            }

            return;
        }

        return actions.update({
            id: data.id,
            ...this.formStateToApi(formState),
        })
            .then(response => {
                const data = response.payload[API_RESOURCE_EVENT];

                if (onSuccess) {
                    return onSuccess(data);
                }

                toast('Dane zostały zaktualizowane');
                this.setState({ forcePlanner: false });
            })
            .catch(error => {
                Logger.error('[ADEPT_PLANNER] Error', error);

                Object.keys(error.payload.validationErrors).map(fieldName => {
                    error.payload.validationErrors[fieldName].map(errorMessage => {
                        toast.error(errorMessage);
                    });
                });

                if (Object.keys(error.payload.validationErrors).length == 0) {
                    toast('Wystąpił błąd, spróbuj ponownie później');
                } 
            });
    }

    getCurrentStepKey = () => {
        const { formState } = this.state;

        if (!formState.userOffer) return 'userOffer';
        if (!formState.product) return 'product';
        if (!formState.location) return 'location';
        
        return 'leadAndTime';
    }

    render() {
        const { location, history, actions, data, predefinedState, disabledSteps, maxDate } = this.props;
        const { formState, isInitialized, forcePlanner, width, isDetailsExpanded } = this.state;
        const currentStepKey = this.getCurrentStepKey();

        if (!isInitialized || (data && !Object.keys(formState).length) || !currentStepKey) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="panel-events-planner"
                styles={require('./styles')}
            >
                {data  && !forcePlanner
                    ? (
                        <div className="dont-force-planner">
                            <div className="details">
                                <div className="detail-item location">
                                    <TextBox 
                                        title="Lokalizacja"
                                        subtitle={formState.location && formState.location.name || 'Brak'}
                                        image={{
                                            isVisible: true,
                                            url: require('Theme/images/icons/location.svg'),
                                        }}
                                        styleVersion={2}
                                    />
                                </div>
                                <div className="detail-item time">
                                    <TextBox 
                                        title="Data"
                                        subtitle={formState.startAt && formState.endAt && data && data.product
                                            ? `${getFormattedDate(formState.startAt, 'datetime')} - Czas trwania: ${data.product?.displayDurationValue || ''} ` + translateTimeUnit(data.product)
                                            : 'Brak'
                                        }
                                        image={{
                                            isVisible: true,
                                            url: require('Theme/images/icons/calendar.svg'),
                                        }}
                                        styleVersion={2}
                                    />
                                </div>
                                {!isDetailsExpanded && (
                                    <div className="expand-details">
                                        <a 
                                            className="expand-details-link"
                                            onClick={() => this.setState({ isDetailsExpanded: true })}
                                        >
                                            Rozwiń szczegóły
                                        </a>
                                    </div>
                                )}
                                {isDetailsExpanded && (
                                    <>
                                        <div className="detail-item category">
                                            <TextBox 
                                                title="Kategoria"
                                                subtitle={getCategoryLabel(formState).label}
                                                image={{
                                                    isVisible: true,
                                                    url: require('Theme/images/icons/training.svg'),
                                                }}
                                                styleVersion={2}
                                            />
                                        </div>
                                        <div className="detail-item user">
                                            <TextBox 
                                                title="Trener"
                                                subtitle={formState.user && getFullName(formState.lead).label || 'Brak'} 
                                                image={{
                                                    isVisible: true,
                                                    url: require('Theme/images/icons/user.svg'),
                                                }}
                                                styleVersion={2}
                                            />
                                        </div>
                                        <div className="detail-item userOffer">
                                            <TextBox 
                                                title="Zamówienie"
                                                subtitle={formState.userOffer && formState.userOffer.name || 'Brak'}
                                                image={{
                                                    isVisible: true,
                                                    url: require('Theme/images/icons/wallet.svg'),
                                                }}
                                                styleVersion={2}
                                            />
                                        </div>
                                        <div className="detail-item product"> 
                                            <TextBox 
                                                title="Produkt"
                                                subtitle={formState.product && formState.product.name || 'Brak'}
                                                image={{
                                                    isVisible: true,
                                                    url: require('Theme/images/icons/finance.svg'),
                                                }}
                                                styleVersion={2}
                                            />
                                        </div>
                                        {(formState.lead && formState.lead.googleMeetUrl) && (formState.location && formState.location.type == LOCATION_TYPE_ONLINE) && (
                                            <div className="detail-item online-training">
                                                <TextBox 
                                                    title="Link do treningu online"
                                                    subtitle={<a className="link" href={formState.lead.googleMeetUrl} target="_blank" rel="noopener noreferrer"> {formState.lead.googleMeetUrl} </a>} 
                                                    image={{
                                                        isVisible: true,
                                                        url: require('Theme/images/icons/star.svg'),
                                                    }}
                                                    styleVersion={2}
                                                />
                                            </div>
                                        )}
                                        <div className="detail-item user-offer-users-type">
                                            <TextBox 
                                                title="Ilość użytkowników biorących udział"
                                                subtitle={data.usersCount}
                                                image={{
                                                    isVisible: true,
                                                    url: require('Theme/images/icons/user-friends.svg'),
                                                }}
                                                styleVersion={2}
                                            />
                                        </div>
                                        {/* {data.eventCycle && (
                                            <div className="detail-item is-from-event-cycle">
                                                <TextBox
                                                    title="Jest wydarzeniem z cyklu"
                                                    subtitle={(
                                                        <Link
                                                            className="event-cycle-link link"
                                                            to={withVariables(
                                                                ADEPT_EVENT_CYCLE.path,
                                                                { id: data.eventCycle.originEventId },
                                                                {}
                                                            )}
                                                        >
                                                            Przejdź do cyklu
                                                        </Link>
                                                    )}
                                                    image={{
                                                        isVisible: true,
                                                        url: require('Theme/images/icons/cycle.svg'),
                                                    }}
                                                    styleVersion={2}
                                                />
                                            </div>
                                        )} */}
                                        <div className="detail-item training-plan">
                                            <TextBox 
                                                title="Plan treningowy"
                                                subtitle={data.trainingPlan}
                                                styleVersion={2}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="route-select">
                                {(data.canMove || data.canDone || data.canCancel) && data.userOffer.isOwner && (
                                    <h2 className="route-select-headline">
                                        Co chcesz zrobić?
                                    </h2>
                                )}
                                {(formState.lead && formState.lead.googleMeetUrl) && (formState.location && formState.location.type == LOCATION_TYPE_ONLINE) && !formState.lead.onlineMeetUrl && (
                                    <div className="routes">
                                        <a className="route" href={formState.lead.googleMeetUrl} target="_blank" rel="noopener noreferrer">
                                            <h3 className="route-headline">
                                                Rozpocznij wideotrening
                                            </h3>
                                        </a>
                                    </div>
                                )}
                                {data.location && data.location.type === LOCATION_TYPE_ONLINE && data.lead && data.lead.onlineMeetUrl && (
                                    <div className="routes">
                                        <a
                                            className="route"
                                            onClick={() => {
                                                history.push(
                                                    withVariables(ADEPT_EVENT_MEET.path, { id: data.id })
                                                );
                                            }}
                                        >
                                            <h3 className="route-headline">
                                                Przejdź do spotkania
                                            </h3>
                                            <p className="route-hint">
                                                Możesz przejść do spotkania online.
                                            </p>
                                        </a>
                                    </div>
                                )}
                                <div className="routes">
                                    {data.canDone && data.userOffer.isOwner && (
                                        <a 
                                            className="route"
                                            onClick={() => actions.done({ id: data.id })}
                                        >
                                            <h3 className="route-headline">
                                                Oznacz jako wykonane
                                            </h3>
                                            <p className="route-hint">
                                                Potwierdź swój udział w tym wydarzeniu.
                                            </p>
                                        </a>
                                    )}
                                    {data.canMove && data.userOffer.isOwner && (
                                        <a 
                                            className="route"
                                            onClick={() => this.setState({ forcePlanner: true })}
                                        >
                                            <h3 className="route-headline">
                                                Przełóż wydarzenie
                                            </h3>
                                            <p className="route-hint">
                                                Możesz bezpłatnie przełożyć wydarzenie do 24h przed jego rozpoczęciem.
                                            </p>
                                        </a>
                                    )}
                                    {data.canCancel && data.userOffer.isOwner && (
                                        <a 
                                            className="route"
                                            onClick={() => {
                                                actions.cancel({ id: data && data.id })
                                                    .then(() => {
                                                        history.push(ADEPT_EVENTS.path);
                                                        toast('Odwołano wydarzenie');
                                                    })
                                                    .catch(() => toast('Wystąpił błąd, spróbuj ponownie później'));
                                            }}
                                        >
                                            <h3 className="route-headline">
                                                Odwołaj wydarzenie
                                            </h3>
                                            <p className="route-hint">
                                                Możesz bezpłatnie odwołać wydarzenie do 24h przed jego rozpoczęciem.
                                            </p>
                                            {!data.canCancelFree && (
                                                <p className="route-hint route-hint-alert">
                                                    Niestety czas bezpłatnego odwołania już minął. 
                                                    Wydarzenie zostanie oznaczone jako anulowane i nie będzie można go przełożyć.
                                                </p>
                                            )}
                                        </a>
                                    )}
                                    {/* {!data.eventCycle && data.lead (
                                        <a
                                            className="route"
                                            onClick={() => {
                                                history.push(
                                                    withVariables(
                                                        ADEPT_EVENT_CYCLE.path,
                                                        { id: data.id },
                                                        {}
                                                    )
                                                );
                                            }}
                                        >
                                            <h3 className="route-headline">
                                                Zarządzaj cyklem
                                            </h3>
                                            <p className="route-hint">
                                                Przejdź do zarządzania cyklem.
                                            </p>
                                        </a>
                                    )} */}
                                </div>
                            </div>
                        </div>
                    )
                    : (
                    <>
                        {width >= 768 && (
                            <StepAccordionV2
                                currentStepKey={this.getCurrentStepKey()}
                                disabled={Boolean(data && data.doneStatus !== EVENT_DONE_STATUS_NOT_DONE)}
                                onSubmit={this.onSubmit}
                                canSubmit={Boolean(formState.endAt && !formState.isSlotOccupied)}
                                formState={formState}
                                steps={[{
                                    key: 'userOffer',
                                    visible: Boolean(!predefinedState.userOffer),
                                    disabled: disabledSteps.userOffer,
                                    title: {
                                        headline: 'Zamówienie',
                                        value: formState.userOffer && formState.userOffer.name,
                                    },
                                    children: (
                                        <StepUserOffer
                                            location={location}
                                            history={history}
                                            formState={formState}
                                            onChangeFormState={nexFormState => this.setState(prevState => ({ 
                                                ...prevState, 
                                                formState: {
                                                    ...prevState.formState,
                                                    ...nexFormState,
                                                },
                                            }), () => this.setData())}
                                        />
                                    ),
                                }, {
                                    key: 'product',
                                    visible: Boolean(!predefinedState.product),
                                    disabled: Boolean(!formState.userOffer || disabledSteps.product),
                                    title: {
                                        headline: 'Produkt',
                                        value: formState.product && formState.product.name,
                                    },
                                    children: (
                                        <StepProduct
                                            location={location}
                                            history={history}
                                            formState={formState}
                                            onChangeFormState={nexFormState => this.setState(prevState => ({ 
                                                ...prevState, 
                                                formState: {
                                                    ...prevState.formState,
                                                    ...nexFormState,
                                                },
                                            }))}
                                        />
                                    ),
                                }, {
                                    key: 'location',
                                    visible: Boolean(!predefinedState.location),
                                    disabled: Boolean(!formState.product || disabledSteps.location),
                                    title: {
                                        headline: 'Lokalizacja',
                                        value: formState.location && formState.location.name,
                                    },
                                    children: (
                                        <StepLocation
                                            location={location}
                                            history={history}
                                            formState={formState}
                                            onChangeFormState={nexFormState => this.setState(prevState => ({ 
                                                ...prevState, 
                                                formState: {
                                                    ...prevState.formState,
                                                    ...nexFormState,
                                                },
                                            }))}
                                        />
                                    ),
                                }, {
                                    key: 'leadAndTime',
                                    visible: Boolean(!predefinedState.lead),
                                    disabled: Boolean((data && !data.canMove) || !formState.product || !formState.location || disabledSteps.leadAndTime),
                                    title: {
                                        headline: 'Termin i Prowadzący',
                                        value: formState.startAt || formState.lead
                                            ? `${(formState.startAt && formState.lead) // If startAt and lead return startAt + '-'
                                                ? getFormattedDate(formState.startAt) + ' - ' 
                                                : formState.startAt  // If only startAt return startAt, if there is not startAt, return ''
                                                    ? getFormattedDate(formState.startAt) 
                                                    : '' } 
                                                ${formState.lead ? getFullName(formState.lead, 'nameSurname').label : ''}` // Return lead if exists
                                            : null,
                                    },
                                    children: (
                                        <StepLeadAndTime
                                            location={location}
                                            history={history}
                                            formState={formState}
                                            onChangeFormState={nexFormState => this.setState(prevState => ({ 
                                                ...prevState, 
                                                formState: {
                                                    ...prevState.formState,
                                                    ...nexFormState,
                                                },
                                            }))}
                                            maxDate={maxDate}
                                        />
                                    ),
                                }]}
                            />
                        )}
                        {width < 768 && (
                            <>
                                <StepAccordion 
                                    currentStepKey={this.getCurrentStepKey()}
                                    disabled={Boolean(data && data.doneStatus !== EVENT_DONE_STATUS_NOT_DONE)}
                                    onSubmit={this.onSubmit}
                                    canSubmit={Boolean(formState.endAt && !formState.isSlotOccupied)}
                                    formState={formState}
                                    steps={[{
                                        key: 'userOffer',
                                        visible: Boolean(!predefinedState.userOffer),
                                        disabled: disabledSteps.userOffer,
                                        title: {
                                            headline: 'Zamówienie',
                                            value: formState.userOffer && formState.userOffer.name,
                                        },
                                        children: (
                                            <StepUserOffer
                                                location={location}
                                                history={history}
                                                formState={formState}
                                                onChangeFormState={nexFormState => this.setState(prevState => ({ 
                                                    ...prevState, 
                                                    formState: {
                                                        ...prevState.formState,
                                                        ...nexFormState,
                                                    },
                                                }))}
                                            />
                                        ),
                                    }, {
                                        key: 'product',
                                        visible: Boolean(!predefinedState.product),
                                        disabled: Boolean(!formState.userOffer || disabledSteps.product),
                                        title: {
                                            headline: 'Produkt',
                                            value: formState.product && formState.product.name,
                                        },
                                        children: (
                                            <StepProduct
                                                location={location}
                                                history={history}
                                                formState={formState}
                                                onChangeFormState={nexFormState => this.setState(prevState => ({ 
                                                    ...prevState, 
                                                    formState: {
                                                        ...prevState.formState,
                                                        ...nexFormState,
                                                    },
                                                }))}
                                            />
                                        ),
                                    }, {
                                        key: 'location',
                                        visible: Boolean(!predefinedState.location),
                                        disabled: Boolean(!formState.product || disabledSteps.location),
                                        title: {
                                            headline: 'Lokalizacja',
                                            value: formState.location && formState.location.name,
                                        },
                                        children: (
                                            <StepLocation
                                                location={location}
                                                history={history}
                                                formState={formState}
                                                onChangeFormState={nexFormState => this.setState(prevState => ({ 
                                                    ...prevState, 
                                                    formState: {
                                                        ...prevState.formState,
                                                        ...nexFormState,
                                                    },
                                                }))}
                                            />
                                        ),
                                    }, {
                                        key: 'leadAndTime',
                                        visible: Boolean(!predefinedState.lead),
                                        disabled: Boolean((data && !data.canMove) || !formState.location || !formState.product ||  disabledSteps.leadAndTime),
                                        title: {
                                            headline: 'Termin i Prowadzący',
                                            value: formState.startAt || formState.lead
                                                ? `${(formState.startAt && formState.lead) // If startAt and lead return startAt + '-'
                                                    ? getFormattedDate(formState.startAt) + ' - ' 
                                                    : formState.startAt  // If only startAt return startAt, if there is not startAt, return ''
                                                        ? getFormattedDate(formState.startAt) 
                                                        : '' } 
                                                        ${formState.lead ? getFullName(formState.lead, 'nameSurname').label : ''}` // Return lead if exists
                                                : null,
                                        },
                                        children: (
                                            <StepLeadAndTime
                                                location={location}
                                                history={history}
                                                formState={formState}
                                                onChangeFormState={nexFormState => this.setState(prevState => ({ 
                                                    ...prevState, 
                                                    formState: {
                                                        ...prevState.formState,
                                                        ...nexFormState,
                                                    },
                                                }))}
                                                maxDate={maxDate}
                                            />
                                        ),
                                    }]}
                                />
                                <div className="footer">
                                    <Button 
                                        onClick={this.onSubmit}
                                        disabled={Boolean(!formState.endAt)}
                                        size="large"
                                        layout="fullWidth"
                                        style="hollow"
                                    >
                                        {data ? 'Przełóż' : 'Zaplanuj'}
                                    </Button>
                                </div>
                            </>
                        )}
                    </>
                    )}
            </StyledComponent>
        );
    }
}