import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import Calendar from 'react-calendar';
import FreeSlotsByScore from 'Models/FreeSlotsByScore';

import { API_RESOURCE_EVENTS_FREE_SLOTS_BY_SCORE } from 'Consts/apiResources';
import { TIME_API_FORMAT, DEFAULT_TIME_INTERVALS } from 'Consts/date';

import { toApiFormat, toAppFormat } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';

moment.locale('pl');

export default class AdeptEventsSlotSelectDate extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            freeSlotsByScore: PropTypes.func.isRequired,
        }).isRequired,
        selectedLead: PropTypes.object,
        selectedLocation: PropTypes.object.isRequired,
        selectedProduct: PropTypes.object.isRequired,
        selectedSlot: PropTypes.object,
        onSelectSlot: PropTypes.func.isRequired,
        maxDate: PropTypes.object,
        lagHoursCount: PropTypes.number,
    };

    static defaultProps = {
        selectedSlot: null,
        selectedLead: null,
        maxDate: moment().add(2, 'months'),
        lagHoursCount: 2,
    };

    state = {
        calendar: [],
        slots: [],
        date: moment().toDate(),
    };

    componentDidUpdate = (prevProps) => {
        const { selectedLocation } = this.props;
        
        if (JSON.stringify(prevProps.selectedLocation) !== JSON.stringify(selectedLocation)) {
            this.loadFreeSlots();
        }
    }

    componentDidMount = () => {
        this.loadFreeSlots();
    }

    loadFreeSlots = () => {
        const { actions, selectedLocation, selectedProduct, selectedLead, lagHoursCount } = this.props;
        const { date } = this.state;

        this.setState({
            slots: [],
        }, () => {
            let startAt = null;

            if (moment(date).isSame(moment(), 'day')) {
                startAt = moment(date)
                    .clone()
                    .add(1, 'hours')
                    .add(lagHoursCount, 'hours')
                    .startOf('hour');
            }

            if (!startAt && moment(date).isSame(moment().add(1, 'days'), 'day') && moment().isAfter(moment().set('hour', 19))) {
                startAt = moment(date)
                    .clone()
                    .set('hour', 9)
                    .startOf('hour'); 
            }

            if (!startAt) {
                startAt = moment(date)
                    .clone()
                    .startOf('day');
            }

            actions.freeSlotsByScore({
                locationId: selectedLocation.id,
                productId: selectedProduct.id,
                leadId: selectedLead?.id,
                startAt: toApiFormat(startAt),
                endAt: toApiFormat(moment(date).endOf('day')),
                timeInterval: DEFAULT_TIME_INTERVALS
            }).then(response => {
                const result = response.payload[API_RESOURCE_EVENTS_FREE_SLOTS_BY_SCORE].map(elem => new FreeSlotsByScore(elem));

                this.setState({
                    slots: result,
                });
            });
        });
    }

    onChangeDate = date => {
        const { onSelectSlot } = this.props;

        onSelectSlot(null);
        this.setState({ date }, () => this.loadFreeSlots());
    }

    onSelectSlot = slot => {
        const { onSelectSlot } = this.props;
        onSelectSlot(slot);
    }

    renderSlotList = (slots, time) => {
        const { selectedSlot } = this.props;

        if (slots.length) {
            slots = slots.filter(slot => slot.trainerId != null);
            if (slots.length) {
                switch (time) {
                    case 'morning':
                        slots = slots.filter(slot => moment(slot.time).isBefore(moment(slot.time).hour(12)));
                        if (slots.length) {
                            return slots.map(slot => (
                                <div
                                    className={classnames({
                                        slot: true,
                                        active: selectedSlot?.time === slot.time,
                                    })}
                                    key={slot.time}
                                    onClick={() => this.onSelectSlot(slot)}
                                >
                                    {toAppFormat(slot.time, TIME_API_FORMAT)}
                                </div>
                            ));
                        }

                        return (
                            <div
                                className={classnames({
                                    slot: true,
                                    disabled: true,
                                })}
                            >
                                Rano
                            </div>
                        );
                    case 'midday':
                        slots = slots.filter(slot => moment(slot.time).isAfter(moment(slot.time).hour(11)) && moment(slot.time).isBefore(moment(slot.time).hour(18)));
                        if (slots.length) {
                            return slots.map(slot => (
                                <div
                                    className={classnames({
                                        slot: true,
                                        active: selectedSlot?.time === slot.time,
                                    })}
                                    key={slot.time}
                                    onClick={() => this.onSelectSlot(slot)}
                                >
                                    {toAppFormat(slot.time, TIME_API_FORMAT)}
                                </div>
                            ));
                        }

                        return (
                            <div
                                className={classnames({
                                    slot: true,
                                    disabled: true,
                                })}
                            >
                                Popołudnie
                            </div>
                        );
                    case 'evening':
                        slots = slots.filter(slot => moment(slot.time).isAfter(moment(slot.time).hour(17)) && moment(slot.time).isBefore(moment(slot.time).hour(24)));
                        if (slots.length) {
                            return slots.map(slot => (
                                <div
                                    className={classnames({
                                        slot: true,
                                        active: selectedSlot?.time === slot.time,
                                    })}
                                    key={slot.time}
                                    onClick={() => this.onSelectSlot(slot)}
                                >
                                    {toAppFormat(slot.time, TIME_API_FORMAT)}
                                </div>
                            ));
                        }

                        return (
                            <div
                                className={classnames({
                                    slot: true,
                                    disabled: true,
                                })}
                            >
                                Wieczór
                            </div>
                        );
                }
            }
        }

        return (
            <div
                className={classnames({
                    slot: true,
                    disabled: true,
                })}
            >
                Brak wolnych terminów
            </div>
        );
    }

    render() {
        const { maxDate } = this.props;
        const { date, slots } = this.state;

        return (
            <StyledComponent
                className="component-adept-events-slot-select-date"
                styles={require('./styles')}
            >
                <div className="body">
                    <div className="column column-time">
                        <div className="time-select">
                            {slots.length === 0 && (<Spinner className="no-bg" />)}
                            {slots.length > 0 && (
                                <div className="slots-container">
                                    <div className="slots-category slots-category-morning">
                                        <h3 className="slot-category-label">
                                            Rano
                                        </h3>
                                        <div className="slots-list">
                                            {this.renderSlotList(slots, 'morning')}
                                        </div>
                                    </div>
                                    <div className="slots-category slots-category-midday">
                                        <h3 className="slot-category-label">
                                            Popołudnie
                                        </h3>
                                        <div className="slots-list">
                                            {this.renderSlotList(slots, 'midday')}
                                        </div>
                                    </div>
                                    <div className="slots-category slots-category-evening">
                                        <h3 className="slot-category-label">
                                            Wieczór
                                        </h3>
                                        <div className="slots-list">
                                            {this.renderSlotList(slots, 'evening')}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="column column-date">
                        <Calendar
                            onChange={this.onChangeDate}
                            value={date}
                            locale={'pl'}
                            maxDate={maxDate.toDate()}
                            minDate={moment().toDate()}
                            maxDetail="month"
                            minDetail="month"
                            activeStartDate={moment().toDate()}
                        />
                    </div>
                </div>
            </StyledComponent>
        );
    }
}
