import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    width: 80vw;

    @media (max-width: ${variables.mobileM}) {
        .modal-body-component {
            .trainer-event-cycle {
                .admin-element-editor {
                    .editor-wrapper {
                        .forms-wrapper {
                            .form-wrapper {  
                                .admin-form-generator {
                                    .form-elements {
                                        .form-element {
                                            .input-external-wrapper {
                                                flex-direction: column !important;
                
                                                .input-internal-wrapper {
                                                    width: 100%;

                                                    .react-datepicker-popper {
                                                        width: 20em;
                                                        left: 9em !important;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
