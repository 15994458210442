import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        .admin-element-editor {
            .editor-wrapper {
                display: block;

                .forms-wrapper {
                    width: 100%;

                    .form-wrapper {
                        border: 0;

                        .admin-form-generator {
                            .form-elements {
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: center;

                                .form-element {
                                    width: 40%;
                                    margin-top: 0;
                                    margin-bottom: 0;

                                    .input-external-wrapper {
                                        display: flex;
                                        flex-direction: column;

                                        .form-error-message-component {
                                            text-align: center;
                                            position: absolute;
                                            bottom: 0;
                                        }
                                    }
                                }

                                .event-cycle-remove-slot-button {
                                    cursor: pointer;
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: ${variables.mobileL}) {
            .admin-element-editor {
                .editor-wrapper {   
                    .forms-wrapper {  
                        .form-elements {
                            .form-element {
                                .input-external-wrapper {
                                    .input-internal-wrapper {
                                        .react-datepicker-popper {
                                            max-width: 20em;
                                            left: 9em !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `;
